export default {
    title: 'Кошик',
    toCheckout: 'Оформити',
    product: 'товар',
    products: 'товарів',
    product_n: 'товари',

    cartInfo: '• Безкоштовна доставка замовлень на суму від 2500 грн<br>' +
        '• Доставка доступна по всій Україні службою “Нова Пошта”',
    cartInfoTemp: '• В період з 05.12 до 06.12 доставка безкоштовна.<br>' +
        '• Доставка доступна по всій Україні службою “Нова Пошта”',

    timeOut: {
        title: 'Час сесії',
        title2: 'вичерпано',
        subTitle: 'Що це означає?',
        text: 'З метою забезпечення онлайн-безпеки і захисту особистих даних сесія браузера припиняється після 20 хвилин відсутності активності. Але час на сайті не минув дарма. Ми зберегли всі вибрані товари в кошику.',
        button: 'Повернутися до кошика'
    },

    popup: {
        here: 'тут',
        forLink: 'посиланням',
        delivery: {
            title: 'Доставка',
            text_01: 'Ми відправляємо товари по всій Україні. Виняток становлять тимчасово окуповані території в Донецькій і Луганській областях, а також Крим.',
            text_02: 'Доставка замовлень по Україні на суму від 2500 грн здійснюється безкоштовно. Якщо сума замовлення складає менше 2500 грн, вартість доставки розраховується на етапі оформлення замовлення на сайті і складає 79 грн для доставки у відділення та 99 грн для кур’єрської доставки за адресою.<br>' +
                'Зверни увагу: якщо варіант оплати вказано готівкою при отриманні, то тобі буде необхідно здійснити додатковий платіж за переказ грошових коштів:<br>' +
                '“Нова Пошта” — 20 грн + 2% від суми замовлення.',
            chapter_01: {
                title: 'Доставка у відділення "Нова пошта"',
                b1: 'Доставка здійснюється до доби в межах Києва і 2 днів по Україні.',
                b2: 'Відстежити замовлення: ' ,
            },
            chapter_02: {
                title: 'Доставка у відділення "Justin"',
                b1: 'Доставка здійснюється протягом 1-2 днів в межах Києва та 2-3 дня по Україні.',
                b2: 'Відстежити замовлення: ' ,
            },
            chapter_03: {
                title: 'Доставка у відділення "УкрПошта"',
                b1: 'Доставка здійснюється протягом 2-3 днів в межах Києва і 3-5 днів по Україні.',
                b2: 'Відстежити замовлення: ' ,
            },
            chapter_04: {
                title: 'Доставка у поштомат "Нова пошта"',
                text: 'На сьогодні доставка на почтомат доступна через кур\'єрську службу «Нова Пошта». Все, що потрібно мати з собою — це мобільний телефон з встановленим додатком «Нова Пошта». Поштомати працюють цілодобово. Після прибуття посилки в поштомат, надійде повідомлення про прибуття. Оплатити замовлення (післяплата та послуги доставки) можна в мобільному додатку «Нова Пошта».' ,
            },
            chapter_05: {
                title: 'Кур\'єрська доставка за адресою',
                text_01: 'Кур\'єрська доставка за адресою можлива, якщо сума замовлення становить менше 14999 грн. Якщо сума замовлення складає більше 14999 грн рекомендуємо доставити замовлення у відділення служби доставки або оплатити замовлення карткою на сайті при оформленні.' ,
                text_02: 'Зверни увагу: термін зберігання замовлення на стороні кур\'єрської компанії обмежений, якщо за 5 днів не буде узгоджена доставка, товар буде повернений на наш склад, замовлення при цьому скасовується.' ,
                text_03: 'Детальніше про умови доставки ти можеш дізнатися за.' ,
            },
        },
        return: {
            title: 'Повернення',
            text_01: 'Можна повернути товар, який з яких-небудь причин не підійшов, за умови збереження його товарного вигляду, упаковки, етикеток, товарної накладної та бажана наявність чека (але не обов\'язково). Саме тому ми радимо приміряти та уважно оглянути товар після його отримання в комфортній обстановці, не зриваючи етикеток. На повернення товару є 60 днів з моменту його покупки.',
            text_02: 'Виняток становлять групи товарів, що не підлягають поверненню/обміну згідно ЗУ. Зі списком товарів, які не підлягають поверненню, ти можеш ознайомитися за',
            chapter: {
                title: 'ПОВЕРНЕННЯ ЗАМОВЛЕНЬ З ІНТЕРНЕТ-МАГАЗИНУ ADIDAS ПО УКРАЇНІ',
                b1: 'Можна частково або повністю відмовитися від замовлення при доставці кур\'єром, якщо товар не підійде (не діє при безконтактній доставці).',
                b2: 'Замовлення, доставлені додому, на відділення служби доставки і в поштомат, можна повернути зараз на центральний склад adidas:<br>' +
                    '- через відділення "Нова пошта".',
            }
        },
        contacts: {
            title: 'Контакти',
            supportTitle: 'Служба підтримки клієнтів',
            supportSchedule: 'Час роботи: з 9:00 до 21:00, без вихідних:',
            supportB1: 'Номер телефону: 0 800 21 22 32',
            supportB2: 'Чат на сайті adidas.ua, іконка чату доступна на кожній сторінці сайту.',
            supportB3: 'Надіслати повідомлення можна через діалогове вікно чату або у месенджері Telegram.',
            supportCancel: 'Для того, щоб скасувати замовлення натисни',
            chapter_01: {
                title: 'Адреси та контакти магазинів',
                text: 'Інформація про роздрібні магазини доступна за ',
            },
            chapter_02: {
                title: 'adidas Group',
                text: 'Більш детальна інформація про діяльність компанії на сайті adidas Group<br>' +
                    'ДП "Адідас-Україна" 03067, м. Киев, вул. Гарматна, 4.',
            },
        }
    },

    sidebar: {
        help: {
            title: 'Потрібна допомога?',
            delivery: 'Доставка',
            return: 'Повернення',
            contacts: 'Контакти',
        },
        pay: {
            title: 'Методи оплати'
        }
    },

    empty: {
        title: 'Кошик порожній',
        text: 'Тут з’являться товари додані до кошика. Почати покупки?',
        toStore: 'За покупками',
        tooltip: 'У кошику немає товарів'
    },

    loading: 'Завантаження кошика',

    content: {
        total: 'Усього',
        size: 'Розмір',
        sizeEnded: 'Цей розмір незабаром скінчиться',
        sizeEnd: 'Намає в наявності',
        count: 'Кількість',
        yourOrder: 'Твоє замовлення',
        firstPrice: 'Початкова ціна',
        sale: 'Знижка',
        withVat: 'включно з ПДВ',
        promocode: 'Промо-код',
        noIdentify: 'не ідентифіковано. Перевір, чи правильний промо-код, та спробуй ще раз.',
        apply: 'Застосувати',
        code: 'Код',
        applied: 'застосовано',
        delivery: 'Доставка',
        enterPromoCode: 'Введи промо-код',
        maxCountTitle: 'Будь ласка, зміни своє замовлення ',
        maxCountText: 'Кількість товарів у кошику перевищує максимальне значення 10.',
    },

    notification: {
        updateCartTitle: {
            title: 'Список доданих у кошик товарів було оновлено.',
            text: ' {name} додано до обраного.'
        }
    },

    applePay: {
        also: 'АБО ВВЕДИ НОМЕР КАРТКИ',
        reject: 'Операція відхилена',
    }
}
