import Vue from 'vue'

const filters = {
    formatDate(date) {
        if (date) {
            const cutDate = date.length <= 10 ? date : date.slice(0, 10);
            return cutDate.split('-').reverse().join('.');
        }
        return ''
    },
    formatPhone(phoneString) {
        return (AppConfig?.formatPhoneRegexp ?? (str => str))(phoneString);
    },
    formatAmountCop(data) {
        if (!data && data !== 0) return '';
        if (data === 0 || data === '0' || data === '00' || data === 0) return '0.00';
        const input = Math.round(data * 100) / 100;
        const reverseArrAmount = String(input).split('.')[0].split('').reverse();
        const amountCop = String(input).split('.').length > 1 ? String(input).split('.')[1] : 0;
        const amountCopStr = amountCop === 0 ? '00' : String(amountCop).length === 2 ? String(amountCop) : String(amountCop) + '0';
        const reverseSplitArrAmount = [];
        reverseArrAmount.forEach((item, i) => {
            if ((i + 1) % 3 === 0) {
                reverseSplitArrAmount.push(item)
                reverseSplitArrAmount.push(' ')
            } else {
                reverseSplitArrAmount.push(item)
            }
        });
        return `${reverseSplitArrAmount.reverse().join('')},${amountCopStr} грн`;
    },
    formatThousandths(data) {
        return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    formatToCurrency(data) {
        if (!data && data !== 0) return '';
        if (data === 0 || data === '0' || data === '00' || data === 0) return '0.00';
        const input = Math.round(data * 100) / 100;
        const reverseArrAmount = String(input).split('.')[0].split('').reverse();
        const cents = String(input).split('.')[1];
        const reverseSplitArrAmount = [];
        reverseArrAmount.forEach((item, i) => {
            if ((i + 1) % 3 === 0) {
                reverseSplitArrAmount.push(item)
                reverseSplitArrAmount.push(' ')
            } else {
                reverseSplitArrAmount.push(item)
            }
        });
        let price = reverseSplitArrAmount.reverse().join('');
        if (cents) {
            price += `.${cents}`;
        }
        return `${price} грн.`;
    },
};

Vue.filter('FormatDate', filters.formatDate);
Vue.filter('FormatPhone', filters.formatPhone);
Vue.filter('FormatAmountCop', filters.formatAmountCop);
Vue.filter('FormatThousandths', filters.formatThousandths);
Vue.filter('toCurrency', filters.formatToCurrency);
Vue.filter('endCountWord', filters.endCountWord);
