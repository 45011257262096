export const state = () => ({
    imagesCount: 1,
});

export const getters = {
    getCurrentImageCount: ({ imagesCount }) => imagesCount,
};
export const mutations = {
    INCREASE_IMAGE_COUNT(state,) {
        state.imagesCount++;
    },
};


export const actions = {
    async getLocalizeData({ dispatch }, lang) {
        try {
            await Promise.allSettled([
                dispatch('header/fetchData', lang),
                dispatch('core/fetchFooterData', lang),
            ]);
        } catch (err) {
            throw err;
        }
    },
    async getUserData({ dispatch }) {
        await dispatch('favorite/getFavorite')
        await dispatch('cart/getCart')
    },
    async nuxtServerInit() {
    }
};



