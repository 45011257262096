export default {
    save:'Сохранить',
    edit:'Редактировать',
    noResults: 'Нет результатов',
    region: 'обл.',
    district: 'р-н',
    seeAll: 'Смотреть все',
    clear: 'Очистить',
    clearAll: 'Очистить все',
    apply: 'Применить',
    show: 'Показать',
    hide: 'Скрыть',
    back: 'Назад',
    toCart: 'Смотреть корзину',
    toCheckout: 'Оформить',
    continueShopping: 'Продолжить покупки',
    needHelp: 'Нужна помощь?',
    contacts: 'Контакты',
    delivery: 'Доставка',
    return: 'Возврат',
    loyalty: 'Программа лояльности',
    continue: 'Продолжить',
    login: 'Войти',
    colorsCount: 'Цветов',
    nextPage: 'Следующая',
    prevPage: 'Предыдущая',
    page: 'Страница',
    from: 'из',
    toTop: 'Вверх',
    productCount: {
        1: 'товар',
        2: 'товара',
        3: 'товаров',
    },
    subscribeBtn: 'подписка на новости',
    product: 'Продукты',
    openFullScreen: 'Открыть на всю стораницу.',
    close: 'Закрыть',
    ofCourse: 'понятно',

    sap: {
        title: 'adidas app',
        description: 'попробуй приложение',
        openApp: 'открыть',
    },

    signUp: {
        blockAgree: {
            title: 'ГОТОВО',
            agreeDesc: 'Подписка успешно оформлена. {0} был добавлен в список рассылки.',
        },
        form: {
            title: 'Подпишись и получи скидку -10% на первую покупку!',
            subtitle: 'Подпишись и узнавай первым о новых поступлениях коллекций.',
            sex: 'Пол',
            male: 'Женский',
            female: 'Мужской',
            noAnswer: 'Не хочу отвечать',
            btn: 'Подписаться',
            info: 'Нажимая кнопку «Подписаться», ты даешь согласие на обработку персональных данных в соответствии с',
            confidential: 'Политикой конфиденциальности',
        },
        agree: {
            title: 'Остался один клик',
            text: 'Письмо со ссылкой для подтверждения подписки отправлено на почту',
            btn: 'Ок',
        },
        thanks: {
            title: 'Спасибо за подписку',
            text: 'Теперь ты будешь получать новости и рассылки от adidas. Проверяй свой почтовый ящик, чтобы узнавать о предстоящих релизах.'
        },
    },

    days: {
        Monday: 'ПН',
        Tuesday: 'ВТ',
        Wednesday: 'СР',
        Thursday: 'ЧТ',
        Friday: 'ПТ',
        Saturday: 'СБ',
        Sunday: 'ВС',
    },
    weekend: 'Выходной',
    askQuestion: 'Задать вопрос',
    soldLabelText: 'Распродано',
    error: {
        title: "Произошла ошибка",
        description: "Попробуйте воспользоваться функционалом позже",
    }
}
